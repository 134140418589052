import agriculturalApi from 'api/agricultural/agricultural-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleScheduleBalance from 'components/CollapsibleScheduleBalance';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { isEmpty } from 'lodash';
import IScheduleBalance, { ICrop, ICropBalanceType, ICulture, IPurpose } from 'model/schedule-balance';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { select } from 'redux-saga/effects';
import agriculturalService from 'services/agricultural/agricultural-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

interface IFilter {
  crop: ICrop;
  culture: ICulture;
}

const ScheduleBalance = ({ t }: WithTranslation) => {
  const [crop, setCrop] = useState<ICrop[]>([]);
  const [selectedCrop, setSelectedCrop] = useState<ICrop>({} as ICrop);
  const [culture, setCulture] = useState<ICulture[]>([]);
  const [selectedCulture, setSelectedCulture] = useState<ICulture>({} as ICulture);
  const [cropType, setCropType] = useState<ICropBalanceType[]>([]);
  const [selectedCropType, setSelectedCropType] = useState<ICropBalanceType>();
  const [purpose, setPurpose] = useState<IPurpose[]>([]);
  const [selectedPurpose, setSeletedPurpose] = useState<IPurpose>();
  const [selectedFilter, setSelectedFilter] = useState<IFilter>({} as IFilter);
  const [scheduleBalance, setScheduleBalance] = useState<IScheduleBalance[]>([]);
  const [reportLink, setReportLink] = useState<string>('');
  const [resetValue, setResetValue] = useState<boolean>(false);

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    if (userRegistration != null) {
      agriculturalService.getCrop(userRegistration).then(setCrop);
    }
  }, [userRegistration]);

  useEffect(() => {
    setResetValue(true);
    if (!isEmpty(selectedCrop?.CD_SAFRA)) {
      agriculturalService.getCulture(userRegistration, selectedCrop?.CD_SAFRA).then(setCulture);
      agriculturalService.getCropType(selectedCrop?.CD_SAFRA).then(setCropType);
    }
  }, [selectedCrop]);

  useEffect(() => {
    if (!isEmpty(selectedCulture?.CD_CULTURA)) {
      agriculturalService.getPurpose(selectedCulture?.CD_CULTURA).then(setPurpose);
    }
  }, [selectedCulture]);

  useEffect(() => {
    if (!isEmpty(selectedCrop?.CD_SAFRA) && !isEmpty(selectedCulture?.CD_CULTURA)) {
      agriculturalService
        .getScheduleBalance(
          userRegistration,
          selectedCrop?.CD_SAFRA,
          selectedCulture?.CD_CULTURA,
          selectedCropType?.IE_TIPO_SAFRA,
          selectedPurpose?.IE_PROPOSITO
        )
        .then(setScheduleBalance);
    }
  }, [selectedCulture, selectedCropType, selectedPurpose]);

  useEffect(() => {
    if (resetValue) {
      console.log('resetValue: ' + resetValue);
      setResetValue(false);
      setScheduleBalance([]);
    }
  }, [resetValue]);

  const handleScheduleBalanceReport = () => {
    if (!isEmpty(selectedCrop?.CD_SAFRA) && userRegistration != null) {
      const reportLinkPath = agriculturalApi.getScheduleBalanceReportLink(
        selectedCrop?.CD_SAFRA,
        userRegistration,
        selectedCulture?.CD_CULTURA,
        selectedCropType?.IE_TIPO_SAFRA,
        selectedPurpose?.IE_PROPOSITO
      );
      setReportLink(reportLinkPath);
    }
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={20} text={t('agricultural.balance')} />
      </StyledPageTitleContainer>
      <StyledPageTitleContainer margin={'25px 0 14px 0'}>
        <MainPageTitle fontSize={16} text={t('agricultural.crop')} />
      </StyledPageTitleContainer>
      <StyledFilters>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('global.field.filterSafra')}
            placeholder={t('global.field.filterSelectSafra')}
            listShowDropdown={crop}
            keyToShowInDropdown="CD_SAFRA_TEXT"
            getSelectedValue={value => setSelectedCrop(value)}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.culture')}
            placeholder={t('global.field.filterSelectCulture')}
            listShowDropdown={culture}
            keyToShowInDropdown="NM_CULTURA"
            getSelectedValue={value => setSelectedCulture(value)}
            isResetValue={resetValue}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.cropType')}
            placeholder={t('global.field.filterSelectCulture')}
            listShowDropdown={cropType}
            keyToShowInDropdown="NM_TIPO_SAFRA"
            getSelectedValue={value => setSelectedCropType(value)}
            isResetValue={resetValue}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.purpose')}
            placeholder={t('global.field.filterSelectCulture')}
            listShowDropdown={purpose}
            keyToShowInDropdown="NM_PROPOSITO"
            getSelectedValue={value => setSeletedPurpose(value)}
            isResetValue={resetValue}
          />
        </StyledFilterContainer>

        {scheduleBalance.length > 0 && (
          <StyledButtonDownloadContainer onClick={() => handleScheduleBalanceReport()}>
            <ButtonDownload href={reportLink} />
          </StyledButtonDownloadContainer>
        )}
      </StyledFilters>
      {scheduleBalance?.map((item, index) => (
        <CollapsibleScheduleBalance
          title={item.DS_PRODUTO}
          quantity={`${StringUtils.maskAmount(item.TOTAL_DISPONIVEL)} ${item.CD_UNIDADE_PADRAO}`}
          details={item.DETALHES}
          key={`table-schedule-balance${index}`}
        />
      ))}
    </>
  );
};

const StyledFilters = styled.div`
  display: flex;
  column-gap: 15px;
`;

const StyledButtonDownloadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -17px;
  margin-left: auto;
`;

export default withTranslation()(ScheduleBalance);
